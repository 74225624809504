import { createMuiTheme } from '@material-ui/core/styles';
import { createTheme, createColor } from '@manakin/theme';
import { green, yellow, beautySecondary, white, grey, mountain, black } from '../colors';
import amber from '@material-ui/core/colors/amber';
import blue from '@material-ui/core/colors/blue';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import loginBackgroundImage from '../../assets/login-background.jpg';
import accountBackgroundImage from '../../assets/account-background.jpg';
import loginVisual from '../../assets/login-visual.svg';
import logo from '../../assets/logo.png';
import pdfIcon from '../../assets/pdf.svg';
import footerLogo from '../../assets/logo.svg';
import calendarVisual from '../../assets/calendar-visual.svg';
import reportVisual from '../../assets/report-visual.svg';

const theme = (obj = {}) => {
	const homePageBlockPrimaryBackgroundColor = obj.homePageBlockPrimaryBackgroundColor;
	const homePageBlockSecondaryBackgroundColor = obj.homePageBlockSecondaryBackgroundColor;

	let _obj = {};

	_obj._primary = '#1868a3';
	_obj._secondary = '#18a395';
	_obj._tertiary = '#0a3a31';
	_obj._palette = obj.themeColor || '#ee6f5c';
	_obj.indication = obj.indicationColor || '#ee6f5c';
	_obj._primaryWorkform = '#fdc330';
	_obj._secondaryWorkform = '#008d43';
	_obj._buttonBorderColor = obj.buttonBorderColor || '#000000';
	_obj.buttonRadius = obj.buttonBorderRadius || '0px';

	//dynamic colors
	_obj.defaultBox = obj.defaultBoxColor || _obj._primary;
	_obj.defaultElement = obj.defaultWorkformColor || obj.defaultElementColor || _obj._primary;
	_obj.primaryElement = obj.primaryWorkformColor || _obj._secondary;
	_obj.secondaryElement = obj.defaultWorkformColor || obj.secondaryElementColor || _obj._tertiary;
	_obj.defaultWorkform = obj.defaultWorkformColor || _obj._palette;
	_obj.primaryWorkform = obj.primaryWorkformColor || _obj._primaryWorkform;
	_obj.secondaryWorkform = obj.secondaryWorkformColor || _obj._secondaryWorkform;
	_obj.buttonBorderColor = createColor(_obj._buttonBorderColor);

	///////COLORS
	_obj.createdPalette = createColor(_obj._palette);
	_obj.indicationColor = createColor(_obj.indication);
	_obj.defaultBoxColor = createColor(_obj.defaultBox);

	//ELEMENT COLORS
	_obj.defaultElementColor = createColor(_obj.defaultElement);
	_obj.primaryElementColor = createColor(_obj.primaryElement);
	_obj.secondaryElementColor = createColor(_obj.secondaryElement);

	//WORKFORM COLORS
	_obj.defaultWorkformColor = createColor(_obj.defaultWorkform);
	_obj.primaryWorkformColor = createColor(_obj.primaryWorkform);
	_obj.secondaryWorkformColor = createColor(_obj.secondaryWorkform);

	//primary and secondary colors
	_obj.primaryColor = createColor(_obj._primary);
	_obj.secondaryColor = createColor(_obj._secondary);
	_obj.tertiaryColor = createColor(_obj._tertiary);
	_obj.mountainColor = createColor(mountain);

	//Palette colors (Used for buttons, icons, navigation enz)
	_obj.primaryPaletteColor = _obj.createdPalette;
	_obj.secondaryPaletteColor = beautySecondary;

	//type colors
	_obj.typeColor = _obj.createdPalette;
	_obj.primaryTypeColor = white;
	_obj.secondaryTypeColor = _obj.primaryPaletteColor;

	//box backgroundColors
	_obj.defaultBoxBGColor = _obj.defaultBoxColor;

	//box name colors
	_obj.defaultBoxColor = _obj.createdPalette;
	_obj.primaryBoxColor = white;
	_obj.secondaryBoxColor = _obj.primaryPaletteColor;

	//element colors
	_obj.defaultElementBGColor = _obj.defaultElementColor;
	_obj.primaryElementBGColor = _obj.primaryElementColor;
	_obj.secondaryElementBGColor = _obj.secondaryElementColor;

	//backgroundColors
	_obj.defaultBackgroundColor = _obj.primaryColor;
	_obj.defaultTitleColor = black;
	_obj.defaultContentColor = createColor('#1a1919');
	_obj.homeLinkColor = _obj.defaultContentColor;
	_obj.secondaryBackgroundColor = grey;

	//Menu colors
	_obj.mainMenuBackground = _obj.primaryPaletteColor;
	_obj.mainMenuColor = beautySecondary;
	_obj.mainMenuSecondaryColor = white;

	//navigation colors
	_obj.navigationColor = _obj.primaryPaletteColor;
	_obj.secondaryNavigationColor = _obj.primaryPaletteColor;
	_obj.linkColor = _obj.primaryPaletteColor;
	_obj.defaultContentHover = _obj.createdPalette;
	_obj.LessonButtonColor = _obj.primaryPaletteColor;
	_obj.defaultBorderHoverColor = _obj.createdPalette;

	//rest
	_obj.defaultBorderColor = createColor('#E6E6E6');
	_obj.softBorderColor = createColor('#EFEFEF');
	_obj.subHeaderColor = createColor('#8A8A8A');
	_obj.correctAnswer = _obj.indicationColor;
	_obj.loginBackground = createColor('#F5F5F5');
	_obj.homePageBlockPrimaryBackgroundColor = createColor(homePageBlockPrimaryBackgroundColor) || obj.primaryWorkform;
	_obj.homePageBlockSecondaryBackgroundColor = createColor(homePageBlockSecondaryBackgroundColor) || obj.secondaryWorkform;

	_obj.contentWidthXL = '1600px';
	_obj.contentWidthL = '1200px';
	_obj.contentWidthM = '1050px';
	_obj.contentWidthS = '700px';
	_obj.contentWidthXS = '340px';
	//breakpoints
	_obj.smartphoneBreakpoint = '@media only screen and (min-width : 375px)';
	_obj.tabletPortraitBreakpoint = '@media only screen and (min-width : 768px)';
	_obj.tabletLandscapeBreakpoint = '@media only screen and (min-width : 1024px)';
	_obj.laptopBreakpoint = '@media only screen and (min-width : 1280px)';
	_obj.desktopBreakpoint = '@media only screen and (min-width : 1400px)';
	_obj.desktopXLBreakpoint = '@media only screen and (min-width : 2100px)';

	//margins and paddings
	_obj.mainMenuListItemPaddingTop = '25px';
	_obj.mainMenuListItemPaddingBottom = '25px';
	_obj.defaultPadding = '25px';

	//fonts
	_obj.defaultTitleFont = "'Grold Rounded Bold', sans-serif";
	_obj.groldBold = "'Grold Bold', sans-serif";
	_obj.secondaryTitleFont = "'Sofia Pro Regular', sans-serif";
	_obj.defaultAnswerFont = "'Sofia Pro Regular', sans-serif";
	_obj.defaultContentFont = "'Sofia Pro Regular', sans-serif";
	_obj.recoletaBold = "'Recoleta Bold', sans-serif";

	_obj.workformTitleFont = {
		fontFamily: _obj.defaultTitleFont,
		fontSize: '4rem',
		lineHeight: '130%',
		letterSpacing: 0,
		fontWeight: 500,
	};

	//heights
	_obj.defaultMobileMenuHeight = '64px';
	_obj.defaultDesktopMenuHeight = '100px';

	_obj.defaultWrapper = {
		maxWidth: _obj.contentWidthM,
		position: 'relative',
		margin: '0 auto',
		width: '100%',
		paddingLeft: '25px',
		paddingRight: '25px',
	};
	_obj.extraSmallWrapper = {
		..._obj.defaultWrapper,
		maxWidth: _obj.contentWidthXS,
	};
	_obj.smallWrapper = {
		..._obj.defaultWrapper,
		maxWidth: _obj.contentWidthS,
	};
	_obj.largeWrapper = {
		..._obj.defaultWrapper,
		maxWidth: _obj.contentWidthL,
	};
	_obj.extraLargeWrapper = {
		..._obj.defaultWrapper,
		maxWidth: _obj.contentWidthXL,
	};

	_obj.footerLogoStyle = {
		width: '10rem',
		height: '3rem',
		backgroundImage: `url(${footerLogo})`,
		backgroundSize: 'contain',
		backgroundRepeat: 'no-repeat',
		position: 'absolute',
		right: '10rem',
		bottom: '3rem',
	};

	_obj.registrationHeading = {
		large: {
			textAlign: 'center',
			width: '100%',
			maxWidth: '600px',
			fontSize: '40px',
			lineHeight: '130%',
			margin: '0 auto 1em',
		},
		small: {
			fontSize: '28px',
			letterSpacing: '.2rem',
		},
	};

	_obj.breakpoints = createBreakpoints({});
	const realTheme = _theme(_obj);

	return realTheme;
};

const _theme = (props) =>
	createMuiTheme({
		// ...theme,
		typography: {
			htmlFontsize: 10,
			fontFamily: [
				props.defaultContentFont,
				'-apple-system',
				'BlinkMacSystemFont',
				'"Segoe UI"',
				'Roboto',
				'"Helvetica Neue"',
				'Arial',
				'sans-serif',
				'"Apple Color Emoji"',
				'"Segoe UI Emoji"',
				'"Segoe UI Symbol"',
			].join(','),
		},
		palette: {
			primary: props.primaryPaletteColor,
			secondary: props.secondaryPaletteColor,
			success: {
				light: green[200],
				main: green[400],
				dark: green[600],
				contrastText: '#ffffff',
			},
			info: {
				light: blue[300],
				main: blue[400],
				dark: blue[500],
				contrastText: '#ffffff',
			},
			warning: {
				light: amber[400],
				main: amber[600],
				dark: amber[700],
				contrastText: '#ffffff',
			},
		},
		manakin: createTheme({
			accountBackgroundImage: accountBackgroundImage,
			calendarVisual: calendarVisual,
			correctAnswer: props.correctAnswer,
			defaultActiveColor: props.primaryPaletteColor,
			defaultAnswerFont: props.defaultAnswerFont,
			defaultBackgroundColor: props.defaultBackgroundColor,
			defaultBorderColor: props.defaultBorderColor,
			defaultBorderHoverColor: props.defaultBorderHoverColor,
			defaultBoxBGColor: props.defaultBoxBGColor,
			defaultBoxColor: props.defaultBoxColor,
			defaultButtonBorderColor: props.buttonBorderColor,
			defaultButtonRadius: props.buttonRadius,
			defaultContentColor: props.defaultContentColor,
			defaultContentFont: props.defaultContentFont,
			defaultContentHover: props.defaultContentHover,
			defaultDesktopMenuHeight: props.defaultDesktopMenuHeight,
			defaultElementBGColor: props.defaultElementBGColor,
			defaultMobileMenuHeight: props.defaultMobileMenuHeight,
			defaultPadding: props.defaultPadding,
			defaultTitleColor: props.defaultTitleColor,
			defaultTitleFont: props.defaultTitleFont,
			defaultWorkformColor: props.defaultWorkformColor,
			defaultWrapper: props.defaultWrapper,
			desktopBreakpoint: props.desktopBreakpoint,
			desktopXLBreakpoint: props.desktopXLBreakpoint,
			extraLargeWrapper: props.extraLargeWrapper,
			extraSmallWrapper: props.extraSmallWrapper,
			homeLinkColor: props.homeLinkColor,
			homePageBlockPrimaryBackgroundColor: props.homePageBlockPrimaryBackgroundColor,
			homePageBlockSecondaryBackgroundColor: props.homePageBlockSecondaryBackgroundColor,
			indicationColor: props.indicationColor,
			laptopBreakpoint: props.laptopBreakpoint,
			largeWrapper: props.largeWrapper,
			layoutButtonStyleSelected: {
				backgroundColor: props.primaryPaletteColor[500],
				border: 0,
				color: white[500],
			},
			LessonButtonColor: props.LessonButtonColor,
			linkColor: props.linkColor,
			loginBackground: props.loginBackground,
			loginBackgroundImage: loginBackgroundImage,
			loginVisual: loginVisual,
			logo: logo,
			mainMenuBackground: props.mainMenuBackground,
			mainMenuColor: props.mainMenuColor,
			mainMenuListItemPaddingBottom: props.mainMenuListItemPaddingBottom,
			mainMenuListItemPaddingTop: props.mainMenuListItemPaddingTop,
			mainMenuSecondaryColor: props.mainMenuSecondaryColor,
			navigationColor: props.navigationColor,
			pdfIcon: pdfIcon,
			primaryBoxColor: props.primaryBoxColor,
			primaryColor: props.primaryColor,
			primaryElementBGColor: props.primaryElementBGColor,
			primaryPaletteColor: props.primaryPaletteColor,
			primaryTypeColor: props.primaryTypeColor,
			primaryWorkformColor: props.primaryWorkformColor,
			reportVisual: reportVisual,
			secondaryBackgroundColor: props.secondaryBackgroundColor,
			secondaryBoxColor: props.secondaryBoxColor,
			secondaryColor: props.secondaryColor,
			secondaryElementBGColor: props.secondaryElementBGColor,
			secondaryNavigationColor: props.secondaryNavigationColor,
			secondaryTitleFont: props.secondaryTitleFont,
			secondaryTypeColor: props.secondaryTypeColor,
			secondaryWorkformColor: props.secondaryWorkformColor,
			smallWrapper: props.smallWrapper,
			smartphoneBreakpoint: props.smartphoneBreakpoint,
			softBorderColor: props.softBorderColor,
			subHeaderColor: props.subHeaderColor,
			tabletLandscapeBreakpoint: props.tabletLandscapeBreakpoint,
			tabletPortraitBreakpoint: props.tabletPortraitBreakpoint,
			tertiaryColor: props.tertiaryColor,
			typeColor: props.typeColor,
			underline: {
				width: '50px',
				height: '2px',
				backgroundColor: props.defaultContentColor[500],
				position: 'absolute',
				bottom: 0,
				left: '50%',
				transform: 'translate(-50%, 2.5rem)',
			},
			workformTitleFont: props.workformTitleFont,
		}),
		overrides: {
			AccordionFaq: {
				panelRoot: {
					backgroundColor: props.secondaryBackgroundColor[500],
				},
			},
			AppAccountWrapper: {
				root: {
					background: 'none',
				},
				title: {
					letterSpacing: '0rem',
				},
			},
			AppBar: {
				menuText: {
					color: black[500],
				},
				dropdownInput: {
					fontSize: '1.4rem',
					letterSpacing: '.3rem',
					fontFamily: props.secondaryTitleFont,
					fontWeight: 'bold',
				},
				menuButtonLabel: {
					fontSize: '2rem',
					fontWeight: 500,
					marginLeft: '22px',
				},
				iconColor: {
					color: props.mountainColor[500],
				},
				buttonRoot: {
					marginLeft: '1rem',
					color: props.mountainColor[500],
				},
				buttonLabel: {
					color: props.mountainColor[500],
				},
				secondaryColors: {
					'& $menuText': {
						color: props.mountainColor[500],
					},
					'& $iconColor': {
						color: props.mountainColor[500],
					},
					'& $backButtonRoot': {
						color: props.mountainColor[500],
					},
					'& $buttonLabel': {
						color: props.mountainColor[500],
					},
				},
			},
			appBestTimes: {
				user: {
					backgroundColor: props.primaryColor[500],
				},
				body: {
					color: white[500],
				},
				currentUser: {
					backgroundColor: props.primaryPaletteColor[500],
					'& $body': {
						color: white[500],
					},
					'& $time': {
						color: white[500],
					},
				},
			},
			AppBettingGameFrontPageBody: {
				root: {
					backgroundColor: white[500],
				},
			},
			AppBettingGameQuestions: {
				points: {
					backgroundColor: props.primaryPaletteColor[500],
					borderRadius: '100%',
				},
			},
			AppBookCheckHeader: {
				headingRoot: {
					letterSpacing: 0,
				},
			},
			AppBox: {
				footerLogo: {
					right: '12rem',
					bottom: '-39px',
				},
			},
			AppBoxCard: {
				backgroundImage: {
					transform: 'scale(1.1)',
				},
				inner: {
					borderRadius: '12px',
					[props.breakpoints.up('md')]: {
						height: '40rem',
						padding: '0 6rem',
					},
					'&:hover': {
						'& $backgroundImage': {
							transform: 'translateX(2%) scale(1.1)',
						},
						'& $overflow': {
							transform: 'scale(1) translate(2%, -20px)',
						},
						'& $boxDescription': {
							visibility: 'visible',
							transform: 'none',
							opacity: 1,
						},
						'& $boxSubtitle': {
							display: 'none',
						},
						'& $boxName': {
							opacity: 0,
							visibility: 'hidden',
							transform: 'translateY(-32px)',
						},
						'& $boxCardLabel': {
							transform: 'none',
						},
					},
				},
				innerDone: {
					[props.breakpoints.up('md')]: {
						height: '30rem',
					},
				},
				boxName: {
					fontWeight: '500',
					letterSpacing: '7px',
					fontFamily: props.groldBold,
					textTransform: 'uppercase',
					transition: 'all .2s ease',
					color: yellow[500],
					[props.breakpoints.up('md')]: {
						fontSize: '4rem',
						lineHeight: '4rem',
						maxWidth: '50%',
					},
				},
				boxCardLabel: {
					color: white[500],
					[props.breakpoints.up('md')]: {
						top: '40px',
					},
				},
				boxCardIconLabel: {
					backgroundColor: white[500],
				},
				boxDescription: {
					position: 'absolute',
					marginTop: 0,
					visiblity: 'hidden',
					opacity: 0,
					transform: 'translateY(32px)',
					transition: 'all .2s ease',
					color: yellow[500],
				},
				boxCardText: {
					color: black[500],
					[props.breakpoints.up('md')]: {
						fontSize: '1.4rem',
					},
				},
				listView: {
					'& $boxName': {
						letterSpacing: 0,
						[props.breakpoints.up('md')]: {
							letterSpacing: 0,
						},
					},
					'&:hover': {
						'& $boxName': {
							opacity: 1,
							transform: 'translateY(0)',
							visibility: 'visible',
						},
					},
				},
				percentage: {
					fontSize: '1.75rem',
					letterSpacing: 0,
					width: '4rem',
					height: '4rem',
					[props.breakpoints.up('md')]: {
						fontSize: '1.75rem',
						width: '4rem',
						height: '4rem',
					},
				},
				percentageDone: {
					backgroundColor: black[500],
					color: white[500],
				},
			},
			AppBoxes: {
				wrapper: {
					maxWidth: '1250px',
				},
				smallWrapper: {
					backgroundColor: 'transparent',
					[props.breakpoints.down('md')]: {
						backgroundColor: 'white',
						paddingTop: '20px',
					},
				},
			},
			AppBoxHeader: {
				root: {
					backgroundColor: '#f5f1ec',
					[props.breakpoints.up('md')]: {
						paddingBottom: '30rem',
					},
				},
				heading: {
					color: black[500],
					textTransform: 'uppercase',
				},
				body: {
					color: black[500],
				},
				resultsPercentage: {
					'&::after': {
						background: `linear-gradient(90deg, rgba(252,122,76,1) 0%, rgba(252,122,76,1) 25%, rgba(252,122,76,0) 25%, rgba(252,122,76,0) 75%, rgba(252,122,76,1) 75%, rgba(252,122,76,1) 100%)`,
						letterSpacing: 0,
						width: '150px',
						color: props.primaryPaletteColor[500],
					},
				},
			},
			AppBoxLayout: {
				wrapper: {
					[props.breakpoints.up('md')]: {
						paddingBottom: '4rem',
					},
				},
				root: {
					position: 'relative',
					paddingBottom: '10rem',
					[props.breakpoints.up('md')]: {
						marginTop: '-28.5rem',
						paddingBottom: '14rem',
					},
				},
				footerLogo: {
					...props.footerLogoStyle,
					backgroundImage: 'none',
					right: '12rem',
				},
				listView: {
					backgroundColor: 'transparent',
					paddingBottom: '0',
				},
				editLink: {
					fontFamily: "inherit"
				}
			},
			AppCaseContent: {
				closeButtonRedo: {
					margin: 0,
					backgroundColor: props.LessonButtonColor[50],
					color: black[500],
					'&:hover': {
						backgroundColor: props.LessonButtonColor[100],
						color: black[500],
					},
				},
				closeButtonClose: {
					backgroundColor: props.LessonButtonColor[500],
					color: white[500],
					'&:hover': {
						backgroundColor: props.LessonButtonColor[600],
						color: white[500],
					},
				},
			},
			AppChoiceQuestion: {
				subHeader: {
					fontFamily: props.secondaryTitleFont,
					color: black[500],
					fontSize: '1.4rem',
					padding: '0 0 .75rem',
					[props.breakpoints.up('md')]: {
						fontSize: '2rem',
					},
					'&::after': {
						backgroundColor: props.primaryPaletteColor[500],
					},
				},
			},
			AppClassList: {
				topBar: {
					backgroundColor: props.secondaryBackgroundColor[500],
				},
			},
			AppContentHeader: {
				title: {
					letterSpacing: '0rem',
				},
			},
			AppDashboardHeader: {
				root: {
					backgroundColor: '#dce6d7',
					[props.breakpoints.up('md')]: {
						paddingBottom: '35rem',
					},
					'& $contentColor': {
						color: props.tertiaryColor[500],
					},
				},
				content: {
					[props.breakpoints.up('md')]: {
						marginBottom: '4.3rem',
						alignItems: 'stretch',
					},
				},
				heading: {
					textTransform: 'uppercase',
				},
				body: {
					marginBottom: 0,
				},
				introduction: {
					display: 'flex',
					flexFlow: 'column nowrap',
					justifyContent: 'center',
					paddingLeft: '3rem',
					borderLeft: `3px solid ${props.primaryPaletteColor[500]}`,
					margin: '2rem 0 3rem',
					[props.breakpoints.up('md')]: {
						margin: '2rem 0',
					},
				},
			},
			AppDownloadButton: {
				wrapper: {
					maxWidth: '1020px',
				},
			},
			AppElementCard: {
				contentColor: {},
				backgroundImage: {},
				root: {
					borderRadius: '12px',
				},
				name: {
					letterSpacing: 0,
					fontWeight: 500,
					fontFamily: props.groldBold,
					[props.breakpoints.up('md')]: {
						fontSize: '3.2rem',
						letterSpacing: 0,
						lineHeight: '4rem',
					},
				},
				type: {
					marginBottom: '1rem',
					fontSize: '1.8rem',
					fontWeight: '500',
				},
				body: {},
				bodytitle: {},
				bookCheck: {
					[props.breakpoints.up('md')]: {
						minHeight: '20rem',
					},
				},
				bookCheckContent: {
					'& $body': {
						margin: 0,
						lineHeight: '1.25',
					},
				},
				button: {
					backgroundColor: 'transparent',
					border: `3px solid ${props.buttonBorderColor[500]}`,
					minHeight: '3rem',
					padding: '1.8rem!important',
					'& span': {
						fontSize: '1.8rem',
					},
				},
				time: {
					[props.breakpoints.up('md')]: {
						top: '3rem',
						left: '3rem',
					},
				},
				done: {
					backgroundColor: '#00a052',
					[props.breakpoints.up('md')]: {
						width: '4rem',
						height: '4rem',
					},
				},
				inCorrect: {
					backgroundColor: 'transparent',
				},
				primaryTitleColor: {
					color: white[500],
				},
				bridgeContent: {
					[props.breakpoints.up('md')]: {
						padding: '80px 60px',
					},
					'& $contentColor': {
						'&>ul': {
							paddingLeft: '2rem',
							marginTop: '3rem',
							[props.breakpoints.up('md')]: {
								paddingLeft: '2rem',
							},
						},
					},
				},
				fullWidth: {
					[props.breakpoints.up('md')]: {
						'&$bookCheck': {
							'& $backgroundImage': {
								width: '20rem',
							},
							padding: '2.5rem 5rem 2.5rem 24rem',
						},
					},
				},
				minHeight: {
					[props.breakpoints.up('md')]: {
						minHeight: '400px',
					},
					'& $done': {
						[props.breakpoints.up('md')]: {
							top: '2rem',
							right: '2rem',
						},
					},
				},
				secondaryTitleColor: {
					color: '#087297',
				},
				secondaryPrefixColor: {
					color: '#087297',
				},
			},
			AppElementOverlay: {
				title: {
					fontFamily: props.defaultTitleFont,
					fontWeight: 'bold',
				},
				closeButton: {
					backgroundColor: black[500],
					color: 'white',
					'&:hover': {
						backgroundColor: black[500],
						color: 'white',
					},
				},
			},
			AppElementsRoot: {
				grid: {
					maxWidth: '1230px',
					marginTop: '-28rem',
				},
			},
			AppFactcardReferral: {
				root: {
					borderRadius: '12px',
					overflow: 'hidden',
					maxWidth: '1200px',
					margin: '0 auto',
				},
				title: {
					fontSize: '2.4rem',
					marginBottom: '1rem',
				},
				body: {
					lineHeight: 1.25,
				},
				multi: {
					'& $title': {
						marginBottom: '.4rem',
					},
					'& $body': {
						marginBottom: '3rem',
					},
				},
				button: {
					backgroundColor: 'transparent',
					border: '3px solid white',
					minHeight: '3rem',
					padding: '1.8rem!important',
					[props.breakpoints.up('md')]: {
						minWidth: '27rem',
					},
					'& span': {
						fontSize: '1.8rem',
					},
				},
			},
			AppFactcardsCard: {
				root: {
					borderRadius: '1rem',
				},
				name: {
					letterSpacing: 0,
					fontWeight: 500,
					fontFamily: props.groldBold,
					[props.breakpoints.up('md')]: {
						fontSize: '3.2rem',
						letterSpacing: 0,
						lineHeight: '5rem',
					},
				},
				backgroundImage: {},
				bodytitle: {
					marginBottom: '1rem',
				},
				bookCheck: {
					'& $backgroundImage': {
						[props.breakpoints.up('md')]: {
							width: '20rem',
						},
					},
					[props.breakpoints.up('md')]: {
						height: '20rem',
						padding: '2.5rem 5rem 2.5rem 24rem',
					},
				},
				bookCheckContent: {
					'& $body': {
						margin: 0,
					},
				},
				button: {
					backgroundColor: 'transparent',
					minHeight: '3rem',
					padding: '1.8rem!important',
					[props.breakpoints.up('md')]: {
						minWidth: '27rem',
					},
					'& span': {
						fontSize: '1.8rem',
					},
				},
				factCardsButton: {
					borderColor: props.buttonBorderColor[500],
				},
			},
			AppFirstPage: {
				wrapper: {
					maxWidth: props.contentWidthM,
				},
				titleRoot: {
					[props.breakpoints.up('md')]: {
						fontSize: '6rem',
						lineHeight: '7rem',
						letterSpacing: '.4rem',
					},
					[props.breakpoints.up('lg')]: {
						fontSize: '6rem',
						lineHeight: '7rem',
						letterSpacing: '.4rem',
					},
				},
			},
			AppHotspotMapLandscape: {
				subHeader: {
					fontFamily: props.secondaryTitleFont,
					color: black[500],
					fontSize: '1.4rem',
					padding: '0 0 .75rem',
					[props.breakpoints.up('md')]: {
						fontSize: '2rem',
					},
					'&::after': {
						backgroundColor: props.primaryPaletteColor[500],
					},
				},
				container: {
					maxWidth: '1200px',
					margin: '0 auto',
				},
			},
			AppImageAndTextCheckQuestion: {
				card: {
					height: '180px',
				},
			},
			AppImageWithSubtitle: {
				image: {
					maxWidth: '1020px',
					margin: '0 auto',
				},
				content: {
					maxWidth: '1200px',
					margin: '0 auto',
				},
			},
			AppLayoutButtons: {
				label: {
					color: props.tertiaryColor[500],
					textTransform: 'uppercase',
					fontFamily: props.groldBold,
					letterSpacing: '3px',
				},
				tabsRoot: {
					marginBottom: '4rem',
				},
				tabSelected: {
					backgroundColor: black[500],
				},
			},
			AppLesson: {
				largeWrapper: {
					maxWidth: '1230px',
					[props.breakpoints.up('md')]: {
						maxWidth: '1230px',
					},
				},
				normalMargin: {
					margin: '3rem 0',
					[props.breakpoints.up('md')]: {
						margin: '8rem 0',
					},
				},
			},
			AppLessonBottom: {
				root: {
					backgroundColor: white[500],
					borderTop: `1px solid ${props.defaultBorderColor[500]}`,
				},
				button: {
					margin: '0 1rem',
					minWidth: '300px',
					fontFamily: props.defaultTitleFont,
				},
				backToBoxButton: {
					display: 'none',
				},
				redoQuestionButton: {
					display: 'flex',
					color: white[500],
				},
				nextQuestionButton: {
					display: 'flex',
				},
			},
			AppLessonHeader: {
				fontStyle: {
					textTransform: 'uppercase',
				},
			},
			AppLessonIntroduction: {
				content: {
					[props.breakpoints.up('md')]: {
						'& :first-child:first-letter': {
							fontSize: '20rem',
							float: 'left',
							lineHeight: '22rem',
							margin: '-30px 3rem 12px 0',
						},
						marginBottom: '4rem',
						fontSize: '2.4rem',
						lineHeight: '4rem',
					},
				},
			},
			AppLogin: {
				root: {
					display: 'flex',
					flexDirection: 'column',
				},
				link: {
					color: white[500],
					'&:visited': {
						color: white[500],
					},
				},
				registrationLink: {
					order: 0,
					marginTop: '2rem',
					'& $button': {
						backgroundColor: 'transparent',
						color: white[500],
						border: `2px solid ${white[500]}`,
					},
				},
				button: {
					background: white[500],
					fontSize: '1.8rem',
					color: props.defaultContentColor[500],
					'&:hover': {
						background: white[500],
						color: props.defaultContentHover[500],
					},
				},
			},
			appLoginHeader: {
				root: {
					backgroundColor: props.createdPalette[500],
					minHeight: 0,
					[props.breakpoints.up('lg')]: {
						minHeight: '800px',
					},
				},
				visualTitle: {
					bottom: 'auto',
					top: '50%',
					left: '50%',
					transform: 'translate(calc(-50% - 481px), calc(-50% + 230px))',
				},
				content: {
					backgroundSize: 'cover',
					backgroundPosition: 'center',
				},
				logo: {
					top: 'auto',
					right: 'auto',
					backgroundImage: `url(${logo})`,
					height: '56px',
					width: '300px',
					backgroundSize: 'contain',
					backgroundRepeat: 'no-repeat',
					position: 'relative',
					backgroundPosition: 'center',
				},
				headerTitle: {
					fontFamily: props.defaultTitleFont,
					color: white[500],
					fontSize: '5rem',
					fontWeight: '500',
					textAlign: 'center',
				},
				// footerLogo: {
				//     ...props.footerLogoStyle,
				// },
			},
			AppLogout: {
				root: {
					backgroundColor: 'white',
				},
			},
			AppMenu: {
				link: {
					color: white[500],
				},
			},
			AppMoodboard: {
				root: {
					maxWidth: '1200px',
					margin: '0 auto',
				},
				card: {
					padding: '2rem',
					[props.breakpoints.up('md')]: {
						padding: '2rem',
					},
				},
				content: {
					maxHeight: '410px',
					overflow: 'hidden',
					[props.breakpoints.up('md')]: {
						maxHeight: '396px',
					},
				},
				text: {
					fontSize: '1.6rem',
					lineHeight: '2rem',
					[props.breakpoints.up('md')]: {
						fontSize: '1.8rem',
						lineHeight: '3rem',
					},
				},
			},
			AppPointsInfo: {
				pointsBody: {
					color: props.primaryPaletteColor.contrastText,
				},
				pointsTitle: {
					color: props.primaryPaletteColor.contrastText,
				},
			},
			appPointsPopup: {
				heading: {
					paddingRight: '30px',
				},
				nextCategory: {
					color: props.defaultContentColor[500],
				},
				points: {
					backgroundColor: props.primaryPaletteColor[500],
					borderRadius: '100%',
				},
				pointsBody: {
					color: props.primaryPaletteColor.contrastText,
				},
			},
			AppProfilePicture: {
				editPicture: {
					color: white[500],
				},
			},

			AppRegistrationAuthData: {
				heading: {
					...props.registrationHeading.large,
					[props.breakpoints.down('md')]: {
						...props.registrationHeading.small,
					},
				},
			},
			AppRegistrationPersonalData: {
				heading: {
					...props.registrationHeading.large,
					[props.breakpoints.down('md')]: {
						...props.registrationHeading.small,
					},
				},
			},
			AppRegistrationSchoolClassData: {
				heading: {
					...props.registrationHeading.large,
					[props.breakpoints.down('md')]: {
						...props.registrationHeading.small,
					},
				},
			},
			AppRegistrationSchoolData: {
				heading: {
					...props.registrationHeading.large,
					[props.breakpoints.down('md')]: {
						...props.registrationHeading.small,
					},
				},
			},
			AppRegistrationScreen: {
				root: {
					minHeight: '100vh',
					backgroundColor: white[500],
				},
				heading: {
					textAlign: 'center',
				},
			},
			AppRegistrationSuccess: {
				root: {
					backgroundColor: props.primaryColor[500],
				},
				content: {
					margin: '0 0 0px',
				},
				heading: {
					...props.registrationHeading.large,
					color: white[500],
					[props.breakpoints.down('md')]: {
						...props.registrationHeading.small,
					},
				},
				paragraph: {
					color: white[500],
				},
				smallParagraph: {
					color: white[500],
				},
			},
			AppResumeButton: {
				root: {
					borderRadius: '1rem',
					backgroundColor: white[500],
					overflow: 'hidden',
					'&:hover': {
						'& $body1': {
							color: props.createdPalette[500],
						},
						'& $longArrow': {
							'& .arrow': {
								backgroundColor: props.createdPalette[500],
							},
						},
					},
				},
			},
			AppSearchInput: {
				root: {
					margin: 0,
				},
			},
			AppSliderInput: {
				sliderLabel: {
					fontSize: '16px',
				},
			},
			AppStatementQuestion: {
				subHeader: {
					fontFamily: props.secondaryTitleFont,
					color: black[500],
					fontSize: '1.4rem',
					padding: '0 0 .75rem',
					[props.breakpoints.up('md')]: {
						fontSize: '2rem',
					},
				},
				root: {
					borderRadius: '12px',
					overflow: 'hidden',
					maxWidth: '1200px',
					margin: '0 auto',
				},
				quote: {
					fontFamily: props.defaultTitleFont,

					fontSize: '2.75rem',
					lineHeight: '1.25',
					letterSpacing: '1px',
				},
				button: {
					backgroundColor: 'transparent',
					border: '3px solid white',
					minHeight: '3rem',
					padding: '1.8rem!important',
					borderRadius: props.buttonRadius,
					[props.breakpoints.up('md')]: {
						minWidth: '27rem',
					},
					'& span': {
						fontSize: '1.8rem',
					},
				},
			},
			AppStatisticsBox: {
				statistics: {
					[props.breakpoints.up('md')]: {
						border: `1px solid black`,
					},
				},
				specificStatisticItem: {
					border: `1px solid ${props.primaryPaletteColor[100]}`,
					[props.breakpoints.up('md')]: {
						border: 0,
					},
				},
			},
			AppTeacherHeader: {
				root: {
					backgroundColor: props.secondaryBackgroundColor[500],
				},
				heading: {
					color: props.defaultTitleColor[500],
				},
				body: {
					color: props.defaultTitleColor[500],
				},
			},
			AppTeacherProgram: {
				root: {
					backgroundColor: props.secondaryBackgroundColor[500],
				},
				title: {
					letterSpacing: '0rem',
				},
			},
			AppTeacherReport: {
				root: {
					backgroundColor: props.secondaryBackgroundColor[500],
				},
				content: {
					'& $heading': {
						color: props.defaultTitleColor[500],
					},
				},
			},
			AppTestCard: {
				root: {
					borderRadius: '12px',
				},
			},
			AppTitleAndText: {
				root: {
					borderRadius: '12px',
					maxWidth: '1200px',
					margin: '0 auto',
				},
				header: {
					textTransform: 'none',
				},
			},
			AppTitleAndTextImage: {
				root: {
					maxWidth: '1200px',
					margin: '0 auto',
				},
				header: {
					textTransform: 'none',
				}
			},
			AppVideoPlayer: {
				playButton: {
					'& $icon': {
						backgroundColor: props.primaryPaletteColor[500],
					},
				},
			},
			AppWorkformLayout: {
				header: {
					fontWeight: '500',
					marginBottom: '7rem',
					fontSize: '3.2rem',
					marginTop: '1.8rem',
					// fontFamily: props.recoletaBold,
					color: black[500],
					[props.breakpoints.down('xs')]: {
						fontSize: '3rem',
					},
				},
				subHeader: {
					fontFamily: props.secondaryTitleFont,
					color: black[500],
					fontSize: '1.4rem',
					padding: '0 0 .75rem',
					[props.breakpoints.up('md')]: {
						fontSize: '2rem',
					},
					'&::after': {
						backgroundColor: props.primaryPaletteColor[500],
					},
				},
				container: {
					maxWidth: '1020px',
				},
			},
			AuthenticationCmsAuthenticator: {
				root: {
					backgroundColor: 'white',
				},
			},
			AuthenticationConfirmReset: {
				root: {
					background: white[500],
				},
			},
			AuthenticationLoginForm: {
				submit: {
					padding: '2rem',
					backgroundColor: white[500],
					color: props.defaultContentColor[500],
					marginTop: '3.4rem',
					'&:hover': {
						backgroundColor: white[500],
						color: props.defaultContentHover[500],
					},
				},
				input: {
					color: white[500],
					'&:before': {
						borderColor: 'rgba(255,255,255,.25)',
					},
					'&:after': {
						borderColor: 'rgba(255,255,255,.25)',
					},
					'&:hover': {
						'&:before': {
							borderColor: 'rgba(255,255,255,.25)!important',
						},
						'&:after': {
							borderColor: 'rgba(255,255,255,.25)!important',
						},
					},
				},
				adornment: {
					color: white[500],
				},
				label: {
					color: 'white!important',
				},
			},
			AuthenticationResetPassword: {
				heading: {
					...props.registrationHeading.large,
					[props.breakpoints.down('md')]: {
						...props.registrationHeading.small,
					},
				},
			},
			ClaimLicense: {
				root: {
					width: '100%',
					height: '100%',
				},
			},
			HeaderFaq: {
				heading: {
					letterSpacing: '0rem',
				},
			},
			HelpButton: {
				icon: {
					marginTop: 0,
				},
			},
			ManakinLoginForm: {
				root: {
					backgroundColor: yellow[500],
				},
			},
			MuiButton: {
				root: {
					zIndex: 1,
					color: props.mountainColor[500],
					height: 'auto',
					padding: '3rem',
					borderRadius: props.buttonRadius,
				},
				contained: {
					boxShadow: 'none',
				},
				containedPrimary: {
					color: props.primaryColor.contrastText,
					'&:hover': {
						backgroundColor: white[500],
						color: 'black',
					},
				},
				label: {
					fontFamily: props.defaultContentFont,
					position: 'relative',
					textTransform: 'none',
					zIndex: 1,
					fontSize: '1.8rem',
					lineHeight: '2rem',
					textDecoration: 'none',
				},
				outlinedPrimary: {
					border: `2px solid ${black[500]}`,
					color: black[500],
					padding: '18px',
					'&:hover': {
						border: `2px solid ${props.primaryPaletteColor[500]}`,
						color: props.primaryPaletteColor[500],
					},
				},
			},
			MuiChip: {
				label: {
					fontSize: '14px',
				},
			},
			MuiDialog: {
				paper: {
					margin: '18px',
					[props.breakpoints.up('md')]: {
						padding: '4.4rem 5rem 1rem',
					},
				},
				paperFullWidth: {
					minHeight: '53rem',
					borderRadius: 0,
				},
			},
			MuiDialogActions: {
				root: {
					justifyContent: 'center',
					flexDirection: 'column',
					[props.breakpoints.up('md')]: {
						margin: 0,
					},
				},
				action: {
					width: '100%',
					padding: '2rem',
				},
			},
			MuiDialogContent: {
				root: {
					[props.breakpoints.up('md')]: {
						padding: 0,
					},
				},
			},
			MuiDialogTitle: {
				root: {
					[props.breakpoints.up('md')]: {
						padding: 0,
					},
					'&>h2': {
						marginBottom: '4rem',
					},
				},
			},
			MuiDrawer: {
				paper: {
					padding: '3rem',
					fontFamily: props.defaultContentFont,
					fontSize: '1.8rem',
					color: props.mainMenuColor,
					background: props.mainMenuBackground[500],
					[props.breakpoints.up('md')]: {
						padding: '1.8rem 4rem',
						fontSize: '3rem',
					},
				},
			},
			MuiFab: {
				primary: {
					backgroundColor: black[500],
					color: white[500],
					'&:hover': {
						backgroundColor: black[400],
					},
				},
			},
			MuiFormControl: {
				root: {
					width: '100%',
				},
			},
			MuiFormControlLabel: {
				root: {
					marginRight: '20px',
					width: '40%',
					'@media (min-width: 1400px)': {
						width: 'auto',
						marginRight: '50px',
					},
				},
			},
			MuiFormGroup: {
				root: {
					width: '100%',
					flexDirection: 'row',
				},
			},
			MuiFormHelperText: {
				root: {
					fontSize: '1.25rem',
				},
			},
			MuiFormLabel: {
				root: {
					fontFamily: props.defaultContentFont,
					fontSize: '1.8rem',
					color: props.defaultContentColor[500],
				},
			},
			MuiInput: {
				input: {
					padding: '14px 0',
					fontSize: '1.8rem',
					lineHeight: '3rem',
				},
			},
			MuiInputBase: {
				inputMultiline: {
					minHeight: '14rem',
				},
			},
			MuiMenuItem: {
				root: {
					fontSize: '1.6rem',
				},
			},
			MuiPickersDay: {
				daySelected: {
					'& >*': {
						margin: 0,
						marginTop: '16px',
					},
				},
				day: {
					'& >*': {
						margin: 0,
						marginTop: '16px',
					},
				},
			},
			MuiPickersSlideTransition: {
				transitionContainer: {
					'& >*': {
						top: '-6px',
					},
				},
			},
			MuiRadio: {
				colorPrimary: {
					color: 'black',
					'&$checked': {
						color: 'black',
					},
				},
			},
			MuiSelect: {
				icon: {
					top: 'calc(50% - 10px)',
				},
			},
			MuiStepConnector: {
				root: {
					display: 'none',
				},
			},
			MuiStepIcon: {
				root: {
					transform: 'scale(1.7)',
					margin: '0 10px',
				},
				text: {
					fontSize: '1.2rem',
				},
			},
			MuiStepLabel: {
				iconContainer: {
					padding: '0 20px 0 0',
				},
				labelContainer: {
					display: 'none',
				},
			},
			MuiStepper: {
				root: {
					backgroundColor: 'transparent',
					padding: '0',
					margin: '30px 0',
				},
			},
			MuiSvgIcon: {
				root: {
					width: '2rem',
					height: '2rem',
				},
			},
			MuiTableCell: {
				root: {
					padding: '4px 16px 4px 16px',
				},
				head: {
					border: 0,
					fontSize: '1.2rem',
					color: props.defaultContentColor[500],
					fontFamily: props.defaultTitleFont,
					lineHeight: '2rem',
					letterSpacing: '.4rem',
				},
				body: {
					paddingTop: '1.5rem',
					paddingBottom: '1.5rem',
				},
			},
			MuiTablePagination: {
				caption: {
					fontSize: '1.6rem',
				},
				select: {
					fontSize: '1.6rem',
					lineHeight: '1.6rem',
					paddingRight: 32,
				},
			},
			MuiTypography: {
				root: {
					fontSize: '2rem',
				},
				h1: {
					fontFamily: props.defaultTitleFont,
					fontSize: '4rem',
					fontWeight: '500',
					lineHeight: '5.2rem',
					color: props.defaultTitleColor[500],
					marginBottom: '1.4rem',
					[props.breakpoints.up('md')]: {
						fontSize: '8rem',
						lineHeight: '8rem',
					},
					[props.breakpoints.up('lg')]: {
						fontSize: '10rem',
						lineHeight: '10rem',
					},
				},
				h2: {
					fontFamily: props.defaultTitleFont,
					fontSize: '2.85rem',
					lineHeight: '3.85rem',
					color: props.defaultTitleColor[500],
					letterSpacing: '7px',
					marginBottom: '1.4rem',
					textTransform: 'uppercase',
					[props.breakpoints.up('md')]: {
						fontSize: '7rem',
						lineHeight: '7rem',
					},
				},
				h3: {
					fontFamily: props.defaultTitleFont,
					fontSize: '3rem',
					lineHeight: '3rem',
					color: props.defaultTitleColor[500],
					fontWeight: 500,
					marginBottom: '1.4rem',
					[props.breakpoints.up('md')]: {
						fontSize: '3.2rem',
						lineHeight: '1.25',
					},
				},
				h4: {
					color: props.defaultTitleColor[500],
					fontFamily: props.defaultTitleFont,
					fontWeight: 500,
					fontSize: '2.4rem',
					lineHeight: '2.4rem',
					marginBottom: '3.6rem',
					[props.breakpoints.up('md')]: {
						lineHeight: '5.2rem',
						marginBottom: '4.6rem',
					},
				},
				h5: {
					color: props.defaultTitleColor[500],
					fontFamily: props.groldBold,
					fontSize: '2.4rem',
					letterSpacing: '1px',
					fontWeight: '500',
					lineHeight: 1,
					marginBottom: '1.3rem',
					[props.breakpoints.up('md')]: {
						fontSize: '4rem',
						marginBottom: '2rem',
					},
				},
				h6: {
					color: props.defaultTitleColor[500],
					fontFamily: props.secondaryTitleFont,
					fontSize: '1.8rem',
					fontWeight: 'bold',
					lineHeight: '2rem',
					marginBottom: '1.3rem',
					letterSpacing: '.2rem',
					[props.breakpoints.up('md')]: {
						marginBottom: '1.6rem',
					},
				},
				subtitle1: {
					fontFamily: props.defaultTitleFont,
					fontSize: '2rem',
					lineHeight: '4rem',
					color: props.defaultContentColor[500],
					marginBottom: '3rem',
				},
				subtitle2: {
					fontFamily: props.propssecondaryTitleFont,
					fontSize: '2.4rem',
					lineHeight: '150%',
					color: props.defaultContentColor[500],
					marginBottom: '1.4rem',
					fontWeight: '500',
					[props.breakpoints.up('md')]: {
						marginBottom: '4rem',
					},
				},
				body1: {
					fontSize: '1.6rem',
					color: props.defaultContentColor[500],
					fontWeight: 400,
					lineHeight: '2.6rem',
					marginBottom: '2rem',
					[props.breakpoints.up('md')]: {
						fontSize: '1.8rem',
						marginBottom: '3rem',
						lineHeight: '3.6rem',
					},
				},
				body2: {
					fontSmoothing: 'auto',
					color: props.defaultContentColor[500],
					fontWeight: 400,
					fontSize: '1.8rem',
					lineHeight: '2.8rem',
					fontFamily: props.secondaryTitleFont,
					marginBottom: '2rem',
					[props.breakpoints.up('md')]: {
						fontSize: '2rem',
						lineHeight: '3.8rem',
					},
				},
				gutterBottom: {
					marginBottom: '3em',
				},
			},
			PrivateValueLabel: {
				label: {
					fontSize: '14px',
				},
			},
			StudentRapportHeader: {
				appBar: {
					// color: 'white',
				},
				button: {
					color: 'white',
					borderColor: 'white',
				},
			},
		},
	});

export default theme;
